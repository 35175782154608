import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from '../assets/images/logo.png';

import facebookIcon from '../assets/images/icons/facebook.svg';
import scanMe from '../assets/images/scan-me.jpg';
import twitIcon from '../assets/images/icons/twitter.svg';
import blogIcon from '../assets/images/icons/blog.svg';
import ytubeIcon from '../assets/images/icons/youtube.svg';
import instaIcon from '../assets/images/icons/instagram.svg';
import phoneIcon from '../assets/images/icons/telephone.png';

const Footer = props => {
  return (
    // <div className="footer mt-50">
    //   <Container>
    //     <Row>
    //         <Col lg="4" sm="12">
    //             <div>
    //                 <img src={logo} alt="Logo" className='logo'/>
    //                 <h4 className='text-uppercase head'>Nurturing Talent Since 1999</h4>
    //                 {/* <h6>© 2024, All Rights Reserved.</h6>
    //                 <h6>ICAD TECHNOLOGIES PVT. LTD.</h6> */}
    //             </div>
    //             <div className='social_icon mb-3'>
    //                 <span className='footer_heading'>Follow Us On :</span>
    //                 <ul>
    //                     <li><a href='https://www.facebook.com/jee.icad/' target="_blank"><img src={facebookIcon} className='svg_img'/></a></li>
    //                     <li><a href='https://twitter.com/#!/ICADSL' target="_blank"><img src={twitIcon} className='svg_img'/></a></li>
    //                     <li><a href='https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch' target="_blank"><img src={ytubeIcon} className='svg_img'/></a></li>
    //                     <li><a href='http://icadsl.blogspot.com/' target="_blank"><img src={blogIcon} className='svg_img'/></a></li>
    //                     <li><a href='https://www.instagram.com/icadsl/?utm_source=ig_profile_share&amp;igshid=1m6rhkv66v0yd' target="_blank"><img src={instaIcon} className='svg_img'/></a></li>
    //                 </ul>
    //             </div>
    //         </Col>
    //         <Col lg="4" sm="12">
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>Corporate Head Office & Study Centre</h1>
    //                 <p>ICAD Heights (West Nagpur) 21, Tilak Nagar,<br/>Opp. Basket Ball Ground<br/>Nagpur - 440010,
    //                 <br/>Maharashtra State, India</p>
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>Contact</h1>
    //                 <p className='mb-0'>+91-9355870236, +91-9355870232</p>
    //                 <p>contact@icadiit.com</p>
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>Quick links</h1>
    //                 <a href='/privacy-policy' className='footer_link'>Privacy Policy</a>
    //                 <a href='/terms-and-conditions' className='footer_link'>terms and conditions</a>
    //                 <a href='/refund-and-cancellation-policy'className='footer_link'>refund and cancellation policy</a>
    //             </div>
    //         </Col>
    //         <Col lg="4" sm="12">
    //             <div>
    //                 <h1 className='footer_heading'>OTHER STUDY CENTERS</h1> 
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>nagpur</h1> 
    //                 <a href='/contact-us' className='footer_link'>Dharmapeth NEET Center</a>
    //                 <a href='/contact-us' className='footer_link'>Hanuman Nagar Center</a>
    //                 <a href='/contact-us'className='footer_link'>Sadar center</a>
    //                 <a href='/contact-us'className='footer_link'>Chhatrapati Nagar center</a>
    //                 <a href='/contact-us'className='footer_link'>Residential campus</a>
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>amravati</h1> 
    //                 <a href='/contact-us' className='footer_link'>training Center</a>
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>chandrapur</h1> 
    //                 <a href='/contact-us' className='footer_link'>training Center</a>
    //             </div>
    //             <div className='mb-30'>
    //                 <h1 className='footer_heading'>yavatmal</h1> 
    //                 <a href='/contact-us' className='footer_link'>training Center</a>
    //             </div>
    //         </Col>
    //     </Row>
    //   </Container>
    // </div>
    <section className="footer">
        <Container>
            <Row>
                <Col lg="4" sm="12">
                    <div className='mb-5'>
                        <img src={logo} alt="Logo" className='logo'/>
                        <h4 className='text-uppercase head'><span style={{marginLeft:"32px"}}>Since 1999</span></h4>
                        {/* <h6>© 2024, All Rights Reserved.</h6>
                        <h6>ICAD TECHNOLOGIES PVT. LTD.</h6> */}
                    </div>
                    <div className='d-flex align-items-center'>
                        <span className='footer_heading' style={{backgroundColor: '#000',padding: '3px',margin: '5px',borderRadius:'4px',
                            border: '1px solid #000'}}><img src={phoneIcon} className='svg_img'/></span> &nbsp;
                        <span><h2 className='mb-0'>+91- 7074347074</h2></span>
                    </div>
                    <div className='social_icon mb-3'>
                        <span className='footer_heading'>Follow Us On :</span>
                        <ul>
                            <li><a href='https://www.facebook.com/jee.icad/' target="_blank"><img src={facebookIcon} className='svg_img'/></a></li>
                            <li><a href='https://twitter.com/#!/ICADSL' target="_blank"><img src={twitIcon} className='svg_img'/></a></li>
                            <li><a href='https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch' target="_blank"><img src={ytubeIcon} className='svg_img'/></a></li>
                            <li><a href='http://icadsl.blogspot.com/' target="_blank"><img src={blogIcon} className='svg_img'/></a></li>
                            <li><a href='https://www.instagram.com/icadsl/?utm_source=ig_profile_share&amp;igshid=1m6rhkv66v0yd' target="_blank"><img src={instaIcon} className='svg_img'/></a></li>
                        </ul>
                    </div>                    
                </Col>
                <Col lg="3" sm="12">
                    <div>
                        <h1 className='footer_heading'>OUR STUDY CENTERS</h1> 
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>nagpur</h1> 
                        <a href='/contact-us' className='footer_link'>Tilak Nagar Head Office</a>
                        <a href='/contact-us' className='footer_link'>Hanuman Nagar Center</a>
                        <a href='/contact-us'className='footer_link'>Sadar center</a>
                        <a href='/contact-us'className='footer_link'>Chhatrapati nagar center</a>
                        <a href='/contact-us'className='footer_link'>Residential campus</a>
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>amravati</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>chandrapur</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                </Col>
                <Col lg="3" sm="12">                            
                    
                    <div className='mb-30'>
                        <h1 className='footer_heading'>yavatmal</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>Nashik</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>Nanded</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                    <div className='mb-30'>
                        <h1 className='footer_heading'>Chhatrapati Sambhaji Nagar</h1> 
                        <a href='/contact-us' className='footer_link'>training Center</a>
                    </div>
                </Col>
                <Col lg="2" sm="12">
                    <div>
                    <h1 className='footer_heading'>Scan Me</h1>
                        <Image src={scanMe} alt="Logo" className='qrcode' fluid></Image>
                    </div>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col sm={12}>
                    <div className='other_links'>                   
                        <a href='/terms-and-conditions' className='footer_link'>Terms & Conditions</a>
                        <a href='/privacy-policy' className='footer_link'>Privacy Policy</a>
                        <a href='/refund-and-cancellation-policy' className='footer_link'>Refund and Cancelation Policy</a>
                    </div>
                </Col>
            </Row>
        </Container>
    </section> 
  );
};

export default Footer;