import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import {Container} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { NavLink, Link, useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import logo from '../assets/images/logo.png';

const Navigation = props => {
  const location = useLocation();
  const {current: dropdownId} = useRef("collapsible-nav-dropdown-" + (Math.random().toString(36)+'00000000000000000').slice(2, 7))
  const [scroll, setScroll] = useState(false);
  // const [show, setShow] = useState(false);

  // const showDropdown = (e)=>{
  //   setShow(!show);
  // }
  // const hideDropdown = e => {
  //     setShow(false);
  // }

  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });    
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
}, [location.pathname]);

  return (   
      <Navbar collapseOnSelect expand="lg" className={scroll ? "sticky" : ""}>
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1">
            <NavLink className="nav-link" to="/">Home</NavLink>
            {/* <NavLink className="nav-link" to="/about">About</NavLink> */}
            <NavDropdown title="About" id={dropdownId} to='/about'>
              <NavDropdown.Item as={Link} to="/about#about-us">About ICAD</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/about#ceo-message">CEO Message</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/about#about-mentors">About Mentors</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/about#core-values">Core Values</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/distinguishing-academics">Distinguishing Academics</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Courses" id="collapsible-nav-dropdown2">
              <NavDropdown.Item as={Link} to="/engineering">Engineering</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/medical">Medical</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/foundation">Foundation</NavDropdown.Item>
            </NavDropdown>
            <NavLink className="nav-link" to="/admission">Admission</NavLink>
            <NavDropdown title="Icadians" id="collapsible-nav-dropdown3">
              <NavDropdown.Item as={Link} to="/icad-stars">ICAD Stars</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/know-icadians">Know Icadians</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/icad-alumni">ICAD Alumini</NavDropdown.Item>
            </NavDropdown>
            <NavLink className="nav-link" to="/results">Results</NavLink>
            <NavDropdown title="Media" id="collapsible-nav-dropdown4">
              <NavDropdown.Item as={Link} to="/news-paper">News Paper</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/gallery">Photo Gallery</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch" target="_blank">Videos</NavDropdown.Item>
            </NavDropdown>
            <NavLink className="nav-link" to="/study-centers">Study Centers</NavLink>
            {/* <NavDropdown title="Study Centers" id="collapsible-nav-dropdown5">
              <NavDropdown.Item as={Link} to="/study-centers#nagpur_center">Nagpur</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Nagpur Residential</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Amravati</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Chandrapur</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="">Yawatmal</NavDropdown.Item>
            </NavDropdown> */}
            <NavLink className="nav-link" to="/downloads">Downloads</NavLink>
            <NavLink className="nav-link" to="/icad-experiance-center">Experience Center</NavLink>
            <NavDropdown title="Contact" id="collapsible-nav-dropdown6">
              <NavDropdown.Item as={Link} to="/contact-us">Contact Us</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/career">Work With Us</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;