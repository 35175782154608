import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, Button, Accordion, Carousel} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import Image from 'react-bootstrap/Image';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navigation from '../components/Navigation';
import Banner from '../components/BannerNew';
import CpaRegistrations from '../components/CpaRegistrations';
import rightArrow from '../assets/images/icons/right-arrows.png';
import medicalIcon from '../assets/images/icons/healthcare.png';
import jeeIcon from '../assets/images/icons/software-application.png';
import schoolIcon from '../assets/images/icons/school-bag.png';
import cetIcon from '../assets/images/icons/mht-cet.png';
import founcationIcon from '../assets/images/icons/foundation.png';
import resultIcon from '../assets/images/icons/result.png';
import dotIcon from '../assets/images/icons/dot_shape.svg';
import faqImage from '../assets/images/faq-image.jpeg';
import ceoImage from '../assets/images/ceo.jpg';
import studentImage from '../assets/images/Devansh_Gattani.png';
import cpaLogo from '../assets/images/cpaLogo.png';
import cpaImage from '../assets/images/CPA.jpg'

import city1 from '../assets/images/icons/cities/Amravati.png';
import city2 from '../assets/images/icons/cities/Chandrapur.png';
import city3 from '../assets/images/icons/cities/Nagpur.png';
import city4 from '../assets/images/icons/cities/Sambhaji-nagar.png';
import city5 from '../assets/images/icons/cities/yavatmal.png';
import city6 from '../assets/images/icons/cities/Nanded.png';
import city7 from '../assets/images/icons/cities/Nashik.png';

import feature1 from '../assets/images/icons/benefits/innovation.png';
import feature2 from '../assets/images/icons/benefits/capsule.png';
import feature3 from '../assets/images/icons/benefits/rocket.png';
import feature4 from '../assets/images/icons/benefits/online-learning.png';
import feature5 from '../assets/images/icons/benefits/question.png';
import feature6 from '../assets/images/icons/benefits/solution.png';
import feature7 from '../assets/images/icons/benefits/famous.png';

import back1 from '../assets/images/dis-academics.png';


const HomeNew = props => {
     const [count, setCount] = useState(0);
      const counters = document.querySelectorAll('.counter');
      const speed = 500; // The lower the slower
    
    counters.forEach(counter => {
        const updateCount = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.innerText;
    
            // Lower inc to slow and higher to slow
            const inc = target / speed;
    
            // console.log(inc);
            // console.log(count);
    
            // Check if target is reached
            if (count < target) {
                // Add inc to count and output in counter
                counter.innerText = Math.round(count + inc);
                // Call function every ms
                setTimeout(updateCount, 1);
            } else {
                counter.innerText = target;
            }
        };
    
        updateCount(setCount);
    });
    
    useEffect(() => {
      setCount();
    }, [count]);
    
    
  return (
    <>
      <Helmet>
        <title>ICAD Study Center - IIT-JEE / NEET coaching in Nagpur, JEE MAINS, JEE ADVANCE</title>
        <meta name="description" content="Welcome to my website. Explore various topics and resources." />
        <meta name="keywords" content="JEE coaching, NEET preparation, foundation program, best IIT coaching Nagpur, personalized education" />
      </Helmet>
   
      <div className='header_wrapper'>        
        <Navigation/>
        <Banner/>
      </div>
      {/* <section className='gradient_sec'>
        <Container>
            <div className=''>
                <Row>
                    <Col lg={5} md={12} sm={12}>
                        <h1 className='title'>India'<span style={{textTransform:'lowercase'}}>s</span> Premier Coaching Institute!</h1>
                        <p>We can showcase here CPA registration platform like something attractive to highlight CPA registration platform.</p>
                        <Button className='theme_btn'>Get Started</Button>
                    </Col>
                    <Col lg={7} md={12} sm={12}>
                        <div className='abs_images'>
                            <div className='abs_img one'>
                                <Image src={ceoImage} alt="ceo image"></Image>
                                <span className='tooltip_text'>ICADIIT is Maharashtra's leading Coaching institute that Creates and Nurture Talent.</span>
                            </div>
                            <div className='abs_img two'>
                                <Image src={studentImage} alt="student image"></Image>
                                <span className='tooltip_text'>Sarang Sir Why ICADIIT?</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>            
        </Container>
      </section> */}
      <section className='cpa_form_sec'>
        <Container fluid>
            <Row nogutters="true" className='h-100'>
                <Col xl={6} lg={12} md={12} sm={12} className='content_left'>
                    <div className='cpa_form img_div' data-aos="zoom-in" data-aos-duration="3000">
                        <img src={cpaImage} className="img-fluid" alt="CPA Image" />                      
                    </div>                   
                </Col>
                <Col xl={6} lg={12} md={12} sm={12} className='content_right'>
                    <div className='cpa_form' data-aos="zoom-in" data-aos-duration="3000">
                          <h2 className='text-center mb-3' style={{ color: '#fff' }}>CPA Registration Form</h2>
                          <h5 className='text-center mb-3' style={{ color: '#fff' }}>Register now by filling out the form and paying the ₹200 fee to secure your spot!</h5>
                          <CpaRegistrations/>
                        </div>
                </Col>
            </Row>
            {/* <div style={{display:'flex',width:'100%',flexDirection:'row'}}>
                <div style={{width:'50%',padding:'30px'}}>
                    <img src={cpaImage} className="img-fluid" alt="CPA Image" />  
                </div>
                <div style={{width:'50%',padding:'30px'}} className='content_right'>
                    <CpaRegistrations/>
                </div>
            </div> */}
        </Container>
      </section>
      <section>
        <Container className='mt-50' data-aos="fade-up" data-aos-duration="3000">
          <h1 className='title text-center'>Our Courses</h1>
          <div className='text-center mb-5'>                
                <p>Explore our expertly designed courses at ICAD to achieve academic excellence and career success!</p>
              </div>
          <Row>
          <Col lg={4} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card courses h-100'>
                <Card.Body>
                    <div className='card_text'>
                        <h4 className='mb-3'>JEE</h4>
                        <div className='course_tags mb-3'>
                            <span>Class 11</span>
                            <span>Class 12</span>
                            <span>Repeater</span>
                        </div>
                        <a href='/engineering'>Explore Course <Image src={rightArrow} alt="Icon" style={{width:'30px'}}></Image></a>
                    </div>
                    <div className='card_bg'>
                        <Image src={jeeIcon} alt="Icon"></Image>
                    </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card courses h-100'>
                <Card.Body>
                    <div className='card_text'>
                        <h4 className='mb-3'>NEET</h4>
                        <div className='course_tags mb-3'>
                            <span>Class 11</span>
                            <span>Class 12</span>
                            <span>Repeater</span>
                        </div>
                        <a href='/medical'>Explore Course <Image src={rightArrow} alt="Icon" style={{width:'30px'}}></Image></a>
                    </div>
                    <div className='card_bg'>
                        <Image src={medicalIcon} alt="Icon"></Image>
                    </div>
                </Card.Body>
              </Card>
            </Col>  
            <Col lg={4} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card courses h-100'>
                <Card.Body>
                    <div className='card_text'>
                        <h4 className='mb-3'>Foundation</h4>
                        <div className='course_tags mb-3'>
                            <span>Excel Program</span>
                            <span>Master Program</span>
                            <span>Olympiad</span>
                        </div>
                        <a href='/foundation'>Explore Course <Image src={rightArrow} alt="Icon" style={{width:'30px'}}></Image></a>
                    </div>
                    <div className='card_bg'>
                        <Image src={founcationIcon} alt="Icon"></Image>
                    </div>
                </Card.Body>
              </Card>
            </Col> 
            <Col lg={4} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card courses h-100'>
                <Card.Body>
                    <div className='card_text'>
                        <h4 className='mb-3'>MHT CET</h4>
                        <div className='course_tags mb-3'>
                            <span>Class 11</span>
                            <span>Class 12</span>
                        </div>
                        <a href='#.'>Explore Course <Image src={rightArrow} alt="Icon" style={{width:'30px'}}></Image></a>
                    </div>
                    <div className='card_bg'>
                        <Image src={cetIcon} alt="Icon"></Image>
                    </div>
                </Card.Body>
              </Card>
            </Col>                      
            <Col lg={4} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card courses h-100'>
                <Card.Body>
                    <div className='card_text'>
                        <h4 className='mb-3'>Results</h4>
                        <div className='course_tags mb-3'>
                            <span>JEE</span>
                            <span>NEET</span>
                            <span>Foundation</span>
                        </div>
                        <a href='/results'>Explore Results <Image src={rightArrow} alt="Icon" style={{width:'30px'}}></Image></a>
                    </div>
                    <div className='card_bg'>
                        <Image src={resultIcon} alt="Icon"></Image>
                    </div>
                </Card.Body>
              </Card>
            </Col>      
          </Row>        
        </Container>
      </section>        
      <section className='mt-50'>
        <Container className='mt-50' data-aos="fade-up" data-aos-duration="3000">
          <h1 className='title text-center'>Our Centers</h1>
          <div className='text-center mb-5'>                
                <p>Explore Excellence Across Maharashtra – Our ICAD Centers are empowering students statewide, <br/>shaping bright futures with world-class education and unparalleled success stories.                </p>
          </div>
          <Row className='d-flex justify-content-center'>
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city3} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Nagpur</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city1} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Amravati</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city2} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Chandrapur</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city5} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Yavatmal</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city7} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Nashik</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col> 
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city6} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Nanded</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col> 
            <Col lg={3} md={6} sm={6} xs={12} className='mb-4'>              
              <Card className='theme_card center_card'>
                <Card.Body>
                    <a href="#.">
                        <Image src={dotIcon} className="corner_shape" alt="dot image"></Image>
                        <div className='d-flex'>
                            <div className='center_img'>
                                <Image src={city4} alt="center icon"></Image>
                            </div>
                            <div className='center_card_text align-self-center'>
                                <h5>Chhatrapati Sambhaji Nagar</h5>
                            </div>
                        </div>                        
                    </a>
                </Card.Body>
              </Card>
            </Col>          
          </Row>        
        </Container>
      </section>
      <section className='counters counter_sec mt-50' data-aos="fade-up" data-aos-duration="3000">
        <Container>
            <div className='main_div'>
                <Row>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no first' data-target="774">0</h1>
                            <p className='counter_text'>Selections in JEE-Advanced</p>
                        </div>
                    
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="5467">0</h1>
                            <p className='counter_text'>Selections in JEE-Main</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="6">6</h1>
                            <p className='counter_text'>International Medals in Olympiad</p>
                        </div>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={6}>
                        <div className='counter_item'>
                            <h1 className='counter counter_no' data-target="568">0</h1>
                            <p className='counter_text'>Selections in NEET-UG</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
    <section className='mt-50'>
        <Container>
            <Row>
                <Col sm={12} className='text-center'>
                    <div className='mb-5' data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                        <h1 className='title mb-3'>Key Features of ICAD</h1>
                        <h2 className='cpa_subtitle'>Here some our multitude of Key Features, <br/>empowering you to achieve your goals.</h2>
                    </div>                            
                </Col>                        
            </Row>
            <Row className='justify-content-center' data-aos="fade-up" data-aos-duration="3000">
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature1} alt="Specialized learning techniquenics icon"></Image>
                        </div>
                        <h6>Specialized learning techniques</h6>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature2} alt="Command capsule icon"></Image>
                        </div>
                        <h6>Command capsule</h6>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature3} alt="Rank booster icon"></Image>
                        </div>
                        <h6>Rank booster</h6>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature4} alt="Online app icon"></Image>
                        </div>
                        <h6>Online app</h6>
                    </div>
                </Col>
            
                <Col lg={3} md={6} sm={6} className='feature_sec'>                              
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature5} alt="Doubt clearing icon"></Image>
                        </div>
                        <h6>Doubt clearing</h6>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature6} alt="Solving system icon"></Image>
                        </div>
                        <h6>Solving system</h6>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} className='feature_sec'>
                    <div className='feature_div'>
                        <div className='icon'>
                            <Image src={feature7} alt="Personalized attention icon"></Image>
                        </div>
                        <h6>Personalized attention</h6>
                    </div>
                </Col>
            </Row>                
        </Container>
    </section>
    <section className='mt-50 cta_sec'>
        <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={back1}
                    alt="first slide"
                  />
                </Carousel.Item>
              <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={back1}
                    alt="second slide"
                  />             
              </Carousel.Item>
              <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={back1}
                    alt="third slide"
                  />           
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={back1}
                  alt="forth slide"
                />      
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={back1}
                  alt="forth slide"
                />     
              </Carousel.Item>              
            </Carousel>
            <div className='caption_text'>
                <h1 className='title'>Find the learning path for you</h1>
                <p>Unlock your potential with expert guidance and top-tier coaching. <br/>Your journey to Success Starts with ICAD</p>
                <div className='btn_group'>
                    <Button className="theme_btn">Enroll Now</Button>
                </div>
            </div>  
      </section>
      <section className='mt-50'>
        <Container>
            <Row>
                <Col md={5} sm={12}>
                    <h1 className='title mb-4'>Frequently Asked Questions <br/> and Answers</h1>
                    <Image src={faqImage} alt="faq-image" className='mb-3' fluid></Image>
                </Col>
                <Col md={7} sm={12}>                    
                    <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>What are the courses offered by ICAD?</Accordion.Header>
                            <Accordion.Body>
                                <p>ICAD offers specialized courses for JEE (Main & Advanced) Exclusive JEE Main and MHT-CET, NEET, and Foundation programs to build a strong academic base.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>What is the success rate of ICAD in JEE and NEET exams?</Accordion.Header>
                            <Accordion.Body>
                                <p>ICAD has consistently produced top rankers in JEE and NEET with a selection ratio of over 50%, hence placing thousands of students in IITs / NITs / AIIMS and Government Medical Colleges.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>What is the fee structure for ICAD courses?</Accordion.Header>
                            <Accordion.Body>
                                <p>ICAD provides detailed fee structures for its courses, which vary depending on the program and duration. Scholarships are also available. These are required to be paid in 2 to 4 installments again depending on the course.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>How can I apply for ICAD's scholarship programs?</Accordion.Header>
                            <Accordion.Body>
                                <p>Scholarships can be availed through the LEAP Exam (National Level Exam) or based on academic performance of previous classes and entrance test CPA conducted by ICAD.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Does ICAD provide online coaching for JEE and NEET?</Accordion.Header>
                            <Accordion.Body>
                                <p>No, ICAD strongly believes in the need of physical classroom training as these exams require a focused approach throughout</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Where are ICAD’s coaching centers located?</Accordion.Header>
                            <Accordion.Body>
                                <p>ICAD centers are located across Maharashtra primarily in  Nagpur, Amravati, Chandrapur, Yavatmal, Nashik, Nanded, and Chhatrapati Sambhaji Nagar. You can find the nearest center on the official website <a href="https://icad.org.in/" target='_blank'>www.icad.org.in</a></p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>What is the CPA Exam by ICAD, and how can I participate?</Accordion.Header>
                            <Accordion.Body>
                                <p>The <b>CPA Exam by ICAD</b> is a scholarship test where students can earn <b>up to 90% fee waivers</b> and other benefits. Visit the ICAD website for <b>registration details</b> and start your journey to success!
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>What is the difference between ICAD's Rank Booster Program and regular courses?</Accordion.Header>
                            <Accordion.Body>
                                <p>The Rank Booster Program focuses on intensive preparation and revision, aimed at improving scores in JEE and NEET.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>What is the eligibility criteria to join ICAD?</Accordion.Header>
                            <Accordion.Body>
                                <p>Eligibility varies by course; students from Class 8 to 12 or those preparing for competitive exams can enroll.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9">
                            <Accordion.Header>How do I get detailed information about ICAD's batches and schedules?</Accordion.Header>
                            <Accordion.Body>
                                <p>Information about batches and schedules is available on the ICAD website ( <a href="https://icad.org.in/" target='_blank'>www.icad.org.in</a>) or through ICAD admissions office.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
      </section>
    </>
  );
};

export default HomeNew;