import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import CpaRegistrationForm from './CpaRegistrations';

const CpaRegistrationFormModal = props => {
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="theme_modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className='subtitle'>CPA Registration Form</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className='cpa_form_sec'>
            <div className='container-fluid'>
              <div nogutters="true" className='h-100 row'>
                <div className='content_right col-xl-12 col-lg-12 col-md-12 col-sm-12' style={{backgroundColor:'#fff',padding:0}}>
                  <div className='cpa_form' style={{border:'none',padding:"15px",borderRadius:'none'}}>
                    <h5 className='text-center mb-3' style={{ color: '#fff' }}>Register now by filling out the form and paying the ₹200 fee to secure your spot!</h5>
                    <CpaRegistrationForm />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CpaRegistrationFormModal;