import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactForm from './ContactForm';

const FormModal = props => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="theme_modal"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2 className='subtitle'>Enquiry Form</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactForm/>
                </Modal.Body>               
                </Modal>
        </>
    );
};

export default FormModal;