import React, {useState, useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';

// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { statesWithCities } from '../data/location';

const ContactForm = props => {
    const statesWithCitiesData = statesWithCities();

    const [selectedState, setSelectedState] = useState('');
    const [cities, setCities] = useState([]); 
    const [captcha, setCaptcha] = useState(''); 
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    
    const [formData, setFormData] = useState({ 
        name: '', 
        email: '',
        contact: '',
        captcha: '',
        state: '',
        citys: '',
        class: '',
        courseInterestedIn: '',
        preferredCenter: '',
        consent: false
    });

    const [responseMessage, setResponseMessage] = useState("");

    // state select change
    const handleStateChange = (e) => {
        const stateName = e.target.value;
        setSelectedState(stateName);

        const states = statesWithCitiesData.find(states => states.name === stateName);
        setCities(states ? states.cities : []);

        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value});
    };

     // Generate a random CAPTCHA string
    const generateCaptcha = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
    };

    useEffect(() => {
        generateCaptcha(); // Generate CAPTCHA on component mount
    }, []);

    const loadCaptha = () => {
        generateCaptcha();
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === "checkbox" ? checked : value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.contact) {
            newErrors.contact = 'Contact number is required';
          }
          // Check if it is a valid format (example: 10 digits)
          else if (!/^\d{10}$/.test(formData.contact)) {
            newErrors.contact = 'Contact number must be 10 digits';
          }   
          if (!formData.email) {
            newErrors.email = 'Email is required';
          }
          else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
          }   
        if (formData.captcha !== captcha || !formData.captcha) newErrors.captcha = 'CAPTCHA does not match. Please try again';
        if (!selectedState) newErrors.state = 'Please select state';
        if (!formData.citys) newErrors.citys = 'Please select city';
        if (!formData.class) newErrors.class = 'Please select Class';
        if (!formData.courseInterestedIn) newErrors.courseInterestedIn = 'Please select Course';
        if (!formData.preferredCenter) newErrors.preferredCenter = 'Please select Center';
        if (formData.consent === false) newErrors.consent = 'Please tick consent checkbox to further process';
        return newErrors;
      };   
   

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validate the inputs
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
          const response = await fetch("https://api.icad.coachinglog.in/api/v1/lead/website", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
               iid: "67108e446d8cc8622b67ca65"
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            setResponseMessage("Thank you! Your message has been sent.");
            setFormData({ name: "", contact: "", email: "", class: "", state: "", courseInterestedIn: "", preferredCenter: "" });
            setErrors({});
            setSuccess(true);
            // console.log(formData);
          } else {
            setResponseMessage("There was an issue sending your message. Please try again.");
          }
        } catch (error) {
          setResponseMessage("Network error. Please try again.");
        }
      };

   
    

    return(
        <>
           <Form onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <input
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        value={formData.name}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                </div>
                <div className='mb-3'>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                </div>
                <div className='mb-3'>
                    <input
                        type="text"
                        name="contact"
                        placeholder="Enter Contact Number"
                        value={formData.contact}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.contact && <span style={{ color: 'red' }}>{errors.contact}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="state"
                        value={selectedState}
                        onChange={handleStateChange}
                        className='form-control'
                        >
                        <option value="">Select a state</option>
                        {statesWithCitiesData.map((state) => (
                            <option key={state.name} value={state.name}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                    {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}
                </div>
                {cities.length > 0 && (
                <div className='mb-3'>
                    <select
                        name="citys"
                        value={formData.city}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option value="">Select a city</option>
                        {cities.map((city) => (
                            <option key={city} value={city}>
                            {city}
                            </option>
                        ))}
                    </select>
                    {errors.citys && <span style={{ color: 'red' }}>{errors.citys}</span>}
                </div>
                )}
                <div className='mb-3'>
                    <select
                        name="class"
                        value={formData.class}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Class Studying In</option>
                        <option value="VII">VII</option>
                        <option value="VIII">VIII</option>
                        <option value="IX">IX</option>
                        <option value="X">X</option>
                        <option value="XI">XI</option>
                        <option value="XII">XII</option>     
                    </select>
                    {errors.class && <span style={{ color: 'red' }}>{errors.class}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="courseInterestedIn"
                        value={formData.courseInterestedIn}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Course Interested In</option>
                        <option value="VIII">VIII</option>
                        <option value="IX">IX</option>
                        <option value="X">X</option>
                        <option value="11 & 12 JEE">11 & 12 JEE</option>
                        <option value="11 & 12 NEET">11 & 12 NEET</option>
                        <option value="12th JEE">12th JEE</option>
                        <option value="12th NEET">12th NEET</option>
                        <option value="JEE Repeater">JEE Repeater</option>
                        <option value="NEET Repeater">NEET Repeater</option>
                        <option value="11 & 12 (JEE main & MHT-CET)">11 & 12 (JEE main & MHT-CET)</option>  
                    </select>
                    {errors.courseInterestedIn && <span style={{ color: 'red' }}>{errors.courseInterestedIn}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="preferredCenter"
                        value={formData.preferredCenter}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Prefered Center</option>
                        <option value="Tilak nagar">Tilak Nagar</option>
                        <option value="Traffic Park Nagpur">Traffic Park Nagpur</option>
                        <option value="Sadar">Sadar</option>
                        <option value="Hanuman Nagar">Hanuman Nagar</option>      
                        <option value="Chhatrapati Nagar">Chhatrapati Nagar</option> 
                        <option value="Nagpur Residential Campus">Nagpur Residential Campus</option>    
                        <option value="Amravati">Amravati</option>    
                        <option value="Chandrapur">Chandrapur</option>   
                        <option value="Yavatmal">Yavatmal</option>    
                        <option value="Aurangabad">Aurangabad</option> 
                        <option value="Nanded">Nanded</option>
                        <option value="Nashik">Nashik</option>    
                    </select>
                    {errors.preferredCenter && <span style={{ color: 'red' }}>{errors.preferredCenter}</span>}
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="6">
                            <div className='captcha_div form-control'>
                                <span>{captcha}</span>
                                <Button onClick={loadCaptha} className='captchaRefreshBtn'>Refresh</Button>
                            </div>                        
                        </Col>
                        <Col sm="6">
                            <input type="text" className="form-control" name="captcha" value={formData.captcha} onChange={handleChange} placeholder="Enter text as shown" />
                        </Col>
                        {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <label className="form-check-label">
                        <input type="checkbox" 
                            className='form-check-input' 
                            name="consent"
                            checked={formData.consent}
                            onChange={handleChange} />
                        &nbsp;&nbsp;I agree to give my consent to receive updates through SMS/Email & WhatsApp*
                    </label>
                    {errors.consent && <span style={{ color: 'red' }}>{errors.consent}</span>}
                </div>


                <Button className='theme_btn d-block mx-auto' type="submit" size='lg'>
                    Submit
                </Button>
                {responseMessage && <p style={{textAlign:'center',marginTop:'5px'}}>{responseMessage}</p>}
           </Form>
        </>
    );
};
    
export default ContactForm;


