import React, {useState} from 'react';
import { Container, Row, Col, Tabs, Tab, Accordion } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

// import jeeMain from '../assets/images/results/JEE-Main.webp';
// import jeeAdvance from '../assets/images/results/JEE-advance.webp';
import medical from '../assets/images/results/medical.webp';
// import cet from '../assets/images/results/CET.webp';

import mhtCetResult from '../assets/images/results/new/7.jpg';
import jeeResult from '../assets/images/results/new/1.jpg';

const OverallResult = props => {
    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Overall Result</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/results">Results</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Overall Result</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Tabs
                        defaultActiveKey="one"
                        id="justify-tab-example"
                        className="mb-3 horizontal_tabs"
                        justify
                        >
                        <Tab eventKey="one" title="JEE">
                            <h1 className='title mt-50'>JEE Result</h1>
                            {/* <Image src={jeeMain} alt="JEE Main Result" className='d-block mx-auto mt-3' fluid></Image> */}
                            {/* <Image src={jeeAdvance} alt="JEE Advance Result" className='d-block mx-auto mt-3' fluid></Image> */}
                            <Image src={jeeResult} alt="JEE Advance Result" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="two" title="Medical">
                            <h1 className='title mt-50'>Medical Result</h1>
                            <Image src={medical} alt="Medical result" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>
                        <Tab eventKey="three" title="MHT-CET">
                            <h1 className='title mt-50'>MHT_CET Result</h1>
                            {/* <Image src={cet} alt="MHT_CET Result" className='d-block mx-auto mt-3' fluid></Image> */}
                            <Image src={mhtCetResult} alt="MHT_CET Result" className='d-block mx-auto mt-3' fluid></Image>
                        </Tab>                       
                    </Tabs>
                </Container>
            </section>
        </>
    );
};

export default OverallResult;