import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import Navigation from '../components/Navigation';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

import logo from '../assets/images/logo.png';

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState(null);
  //const [myBody, setBody] = useState(null);
  const [myOrder, setOrder] = useState(null);

  //const data = { order_id: 1, txn_id: "John_Doe_12131313", amount: 200, customer_email: "New@York.com", customer_phone: "9511718779", udf1: "test", udf6: "test", udf7: "test", udf8: "test", udf9: "test", udf10: "test" };

  useEffect(() => {
    const encMessage = searchParams.get('message');
    //const encBody = searchParams.get('body');
    const encOrder = searchParams.get('order');

    if (encMessage && encOrder) {
      // test //
      //setMessage("Your Order Successfull");
      //setOrder(data);
      // live //
      setMessage(atob(encMessage));
      //console.log(JSON.parse(atob(encOrder)));
      setOrder(JSON.parse(atob(encOrder)));
    }
  }, [searchParams]);

  const convertToIST = (dateString) => {
    const date = new Date(dateString);
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(date);

    return `${formattedDate} (IST)`;
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    // Load Logo Image (Centered)
    const imgWidth = 40;
    const imgHeight = 20;
    const pageWidth = doc.internal.pageSize.getWidth(); // Get page width
    const centerX = (pageWidth - imgWidth) / 2; // Calculate center position
    doc.addImage(logo, "PNG", centerX, 10, imgWidth, imgHeight);

    // Add Heading Below the Logo (Centered)
    doc.setFontSize(18);
    doc.text("CPA Registration Transaction Details", pageWidth / 2, 50, { align: "center" });

    // Table Data (Key-Value Pairs)
    const tableRows = [
      ["Order Id", myOrder.order_id],
      ["Transaction ID", myOrder.txn_id],
      ["Amount", myOrder.amount],
      ["Transaction Status", myOrder.status],
      ["Date", convertToIST(myOrder.txn_detail.created)],
      ["Name", myOrder.udf1],
      ["Email", myOrder.customer_email],
      ["Contact", myOrder.customer_phone],
      ["State", myOrder.udf6],
      ["City", myOrder.udf7],
      ["Class", myOrder.udf8],
      ["Course Interested In", myOrder.udf9],
      ["Preferred Center", myOrder.udf10]
    ];

    // Add Table Below the Title
    autoTable(doc, {
      head: [], // Column Titles
      body: tableRows,
      startY: 60, // Table starts after heading
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 3 }
    });

    // Save PDF
    doc.save("cpa-transaction-details.pdf");
  };

  return (
    <>
      <Helmet>
        <title>Payment Status - ICAD Study Center</title>
        <meta name="description" content="Learn more about us and our mission." />
      </Helmet>

      <div className="header_wrapper">
        <Navigation />
      </div>

      {message && (
        <section className="mt-40" id="about-us">
          <Container>
            <Row>
              <Col sm={12} className="text-center mt-5" data-aos="fade-up" data-aos-duration="3000">
                <h2 className="subtitle">{message.replace("order", "CPA Registration")}</h2>

                {/* Body Data Table */}
                {/* <center>
                  <font size="4" color="black"><b>Your Transcations Details</b></font>
                  <table border="1">
                    <thead>
                      <tr>
                        <th>Key</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myBody &&
                        Object.entries(myBody).map(([key, value]) => (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{JSON.stringify(value, null, 2)}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </center> */}

                {/* Order Data Table */}
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: "10px" }}>
                  <Button className='theme_btn d-block download-button' type="button" size='sm' onClick={downloadPDF}>
                    Download Receipt
                  </Button>
                </div>
                <center>
                  <font size="4" color="black" className="mt-5"><b>Your Transcation Details</b></font>
                  <table border="1" className="table">
                    <tbody>
                      {myOrder &&
                        <>
                          <tr>
                            <th>Order Id</th>
                            <td>{myOrder.order_id}</td>
                          </tr>
                          <tr>
                            <th>Transaction ID</th>
                            <td>{myOrder.txn_id}</td>
                          </tr>
                          <tr>
                            <th>Amount</th>
                            <td>{myOrder.amount}</td>
                          </tr>
                          <tr>
                            <th>Transaction Status</th>
                            <td>{myOrder.status}</td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td>{myOrder.udf1}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{myOrder.customer_email}</td>
                          </tr>
                          <tr>
                            <th>Contact</th>
                            <td>{myOrder.customer_phone}</td>
                          </tr>
                          <tr>
                            <th>State</th>
                            <td>{myOrder.udf6}</td>
                          </tr>
                          <tr>
                            <th>City</th>
                            <td>{myOrder.udf7}</td>
                          </tr>
                          <tr>
                            <th>Class</th>
                            <td>{myOrder.udf8}</td>
                          </tr>
                          <tr>
                            <th>Course Interested In</th>
                            <td>{myOrder.udf9}</td>
                          </tr>
                          <tr>
                            <th>Preferred Center</th>
                            <td>{myOrder.udf10}</td>
                          </tr>
                        </>
                      }
                    </tbody>
                  </table>
                </center>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default PaymentSuccess;