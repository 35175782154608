import React from 'react';
import {Container, Row, Col, Accordion, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Navigation from '../components/Navigation';
import infoBrochure from '../assets/images/documents/info-brochure-new.pdf';
import NEETRepeater from '../assets/images/documents/NEET-repeater.pdf';
import CPAApplication from '../assets/images/documents/CPA-ApplicationForm.pdf';
import CPAX from '../assets/images/documents/CPA-sample-paper-x.pdf';
import CPAIX from '../assets/images/documents/CPA-sample-paper-ix.pdf';
import CPAXVIII from '../assets/images/documents/CPA-sample-paper-viii.pdf';
import courseEng from '../assets/images/documents/2year-course-xi.pdf';
import courseMedical from '../assets/images/documents/2year-course-medical.pdf';
import CBSEBoardBook from '../assets/images/documents/CBSE-Board-2024-Exam-Revision-Material.pdf';


const Downloads = props => {
    return(
        <main>
            <div className='header_wrapper'>
                <Navigation />
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Downloads</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Downloads</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <Row className='mb-30'>
                        <Col sm={12}>
                            <div className='mb-30'>
                                <h2 className='title'>Downloads</h2>
                            </div>
                            <Card className='theme_card'>
                                <Card.Body>
                                    <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>CBSE Board Book</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='download_list'>
                                                    <li><a href={CBSEBoardBook} target='_blank'>CBSE Board Book</a></li>                                                    
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>CPA Entrance Admission Form</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='download_list'>
                                                    <li><a href={CPAApplication} target='_blank'>ICAD's Entrance (CPA) Application Form </a></li>                                                    
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Sample Paper</Accordion.Header>
                                            <Accordion.Body>
                                               <ul className='download_list'>
                                                    <li><a href={CPAX} target='_blank'>CPA Sample Paper - X Moving</a></li>
                                                    <li><a href={CPAIX} target='_blank'>CPA Sample Paper - IX Moving</a></li>
                                                    <li><a href={CPAXVIII} target='_blank'>CPA Sample Paper - VIII Moving</a></li>
                                                    <li><a href={courseEng} target='_blank'>2 years course- XI Moving-Engineering</a></li>
                                                    <li><a href={courseMedical} target='_blank'>2 years course- XI Moving-Medical</a></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Brochure</Accordion.Header>
                                            <Accordion.Body>
                                                <ul className='download_list'>
                                                    <li><a href={infoBrochure} target='_blank'>Information Brochure</a></li>
                                                    <li><a href={NEETRepeater} target='_blank'>NEET Repeaters</a></li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
;}

export default Downloads;