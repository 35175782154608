import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';


import AOS from 'aos';
import 'aos/dist/aos.css';

import './assets/css/style.css';

import TopBar from './components/TopBar';


import Footer from './components/Footer';
import TopFooter from './components/TopFooter';
import BottomFooter from './components/BottomFooter';

import Home from './pages/HomeNew';
import HomeNew from './pages/Home';
import About from './pages/About';
import Engineering from './pages/Engineering';
import Medical from './pages/Medical';
import Foundation from './pages/Foundation';
import Admission from './pages/Admission';
import Gallery from './pages/Gallery';
import ContactUs from './pages/ContactUs';
import Results from './pages/Results';
import Career from './pages/Career';
import AcademicJobDescription from './pages/AcademicJobDescription';
import NonAcademicJobDescription from './pages/NonAcademicJobDescription';
import StudyCenters from './pages/StudyCenters';
import GalleryDetails from './pages/GalleryDetails';
import KnowIcadians from './pages/KnowIcadians';
import IcadiansDetails from './pages/IcadiansDetails';
import RefundAndCancellationPolicy from './pages/RefundAndCancellationPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Blog from './pages/Blog';
import BlogDetails from './pages/BlogDetails';
import IcadAlumni from './pages/IcadAlumni';
import NewsPaper from './pages/NewsPaper';
import IcadDigital from './pages/IcadDigital';
import Downloads from './pages/Downloads';
import IcadStars from './pages/IcadStars';
import ExperienceCenter from './pages/ExperienceCenter';
import DistinguishingAcademics from './pages/DistinguishingAcademics';
import FoundationResult from './pages/FoundationResult';
import MedicalResult from './pages/MedicalResult';
import EngineeringResult from './pages/EngineeringResult';
import OverallResult from './pages/OverallResult';
import AboutAcademics from './pages/AboutAcademics';
import IcadCpa from './pages/IcadCpa';
import FormModal from './components/FormModal';
import PaymentSuccess from './pages/PaymentSuccess';

import angleupIcon from './assets/images/icons/angle-up.png';

function App() { 
  const [modalShow, setModalShow] = React.useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll effect
    });
  };

  const [cpaPage, setCpaPage] = useState(false);

  const pathname = window.location.pathname;
  

  useEffect(() => {
    const animationLoading = async () => {
      AOS.init();
    };
    const cpaCOndition = async () => {
      if(pathname === '/icadcpa'){
        setCpaPage(true)
      }else{
        setCpaPage(false)
      }
    };

    const dnbScript = async () => {
      const script = document.createElement("script");
      script.src = "http://dunsregistered.dnb.com";
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        // Reset any unwanted styles
        document.body.style.margin = "0";
      };  
      return () => {
        document.head.removeChild(script);
      };    
    };

    animationLoading();
    cpaCOndition();
    dnbScript();  
    
  }, [cpaPage]);

  return (
    <main>      
      {!cpaPage && <TopBar/>}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home-new" element={<HomeNew />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/engineering" element={<Engineering />}></Route>
          <Route path="/medical" element={<Medical />}></Route>
          <Route path="/foundation" element={<Foundation />}></Route>
          <Route path="/admission" element={<Admission />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/results" element={<Results />}></Route>
          <Route path="/career" element={<Career />}></Route>
          <Route path="/academic-job-description/:academicItemId" element={<AcademicJobDescription />}></Route>
          <Route path="/non-academic-job-description/:nonAcademicItemId" element={<NonAcademicJobDescription />}></Route>
          <Route path="/study-centers" element={<StudyCenters />}></Route>
          <Route path="/gallery-details" element={<GalleryDetails />}></Route>
          <Route path="/know-icadians" element={<KnowIcadians />}></Route>
          <Route path="/icadians-details/:icadiansPersonId" element={<IcadiansDetails />}></Route>
          <Route path="/refund-and-cancellation-policy" element={<RefundAndCancellationPolicy />}></Route>
          <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/blog" element={<Blog />}></Route>
          <Route path="/blog-details/:blogItemId" element={<BlogDetails />}></Route>
          <Route path="/icad-alumni" element={<IcadAlumni />}></Route>
          <Route path="/news-paper" element={<NewsPaper />}></Route>
          <Route path="/icad-digital" element={<IcadDigital />}></Route>
          <Route path="/downloads" element={<Downloads />}></Route>
          <Route path="/icad-stars" element={<IcadStars />}></Route>
          <Route path="/icad-experiance-center" element={<ExperienceCenter />}></Route>
          <Route path="/distinguishing-academics" element={<DistinguishingAcademics />}></Route>
          <Route path="/foundation-result" element={<FoundationResult />}></Route>
          <Route path="/medical-result" element={<MedicalResult />}></Route>
          <Route path="/engineering-result" element={<EngineeringResult />}></Route>
          <Route path="/overall-result" element={<OverallResult />}></Route>
          <Route path="/about-academics" element={<AboutAcademics />}></Route>
          <Route path="/icadcpa" element={<IcadCpa />}></Route>
          <Route path="/payment-success" element={<PaymentSuccess />}></Route>
        </Routes>
      </BrowserRouter>

      {!cpaPage && <TopFooter/>}
      <Footer/>
      {!cpaPage && <BottomFooter/>}
       {/* Button to scroll to top */}
       <button onClick={scrollToTop} className="scroll_btn" title='Move to up'>
          <Image src={angleupIcon} alt="move to up icon"></Image>
      </button>
      <Button className='theme_btn scroll_btn float_btn' onClick={() => setModalShow(true)}>Enquire Now</Button>

      <FormModal
          show={modalShow}
          onHide={() => setModalShow(false)}
      />
    </main>
  );
}

export default App;
