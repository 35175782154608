import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const BottomFooter = props => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <section className='bottom_footer'>
                <Container>
                    <Row>
                        <Col sm='9'>
                            <p className='mb-0'>© Copyrights {currentYear} ICAD Technologies PVT. LTD. & ICAD Publications PVT. LTD. All rights reserved.</p>
                        </Col>
                        <Col sm='3'>
                            <p className='mb-0 text-center text-sm-end'>Designed with Love by <a href="https://maxsolz.com/" target="_blank" style={{color:"#fff",textDecoration:"none"}}>MaxSolz</a></p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default BottomFooter;