import React, {useState} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import Navigation from '../components/Navigation';

import Breadcrumb from 'react-bootstrap/Breadcrumb';

import nationalMath from '../assets/images/results/foundation-result1.webp';
import nationalPhysics from '../assets/images/results/foundation-result2.webp';
import nationalTalent from '../assets/images/results/foundation-result3.webp';
import nationalChemistry from '../assets/images/results/foundation-result4.webp';
import nationalMathOly from '../assets/images/results/foundation-result5.webp';
import KvpyFelloship from '../assets/images/results/foundation-result6.webp';
import nationalStandard from '../assets/images/results/foundation-result7.webp';
import pradnyaExam from '../assets/images/results/foundation-result8.webp';
import pravinyaExam from '../assets/images/results/foundation-result9.webp';
import prmo from '../assets/images/results/foundation-result10.webp';
import regionalMath from '../assets/images/results/foundation-result11.webp';
import scholarship from '../assets/images/results/foundation-result12.webp';

import foundResult from '../assets/images/results/new/11.jpg';

const FoundationResult = props => {
    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Foundation Result</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/results">Results</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Foundation Result</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={foundResult} alt="foundation result" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalMath} alt="National Mathematics Talent Contest" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalPhysics} alt="National Physics olympaid" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalTalent} alt="National Talent Search Exam" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalChemistry} alt="National Chemistry olympaid" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalMathOly} alt="National Mathematics Olympaid" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={KvpyFelloship} alt="KVPY Fellowship Highlights" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={nationalStandard} alt="National Standard Exam in Chemistry" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={pradnyaExam} alt="Pradnya Exam" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={pravinyaExam} alt="Pravinya Exam" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={prmo} alt="PRMO Qualified Students" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={regionalMath} alt="Regional Mathematics Olympaid" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Image src={scholarship} alt="Scholarship Exam Result" className='d-block mx-auto' fluid></Image>
                </Container>
            </section>
        </>
    );
};

export default FoundationResult;