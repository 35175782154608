import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Card, Accordion, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet';
import Carousel from 'react-bootstrap/Carousel';
import Modal from '../components/Modal';
import Slider from "react-slick";

import logo from '../assets/images/logo.png';
import facebookIcon from '../assets/images/icons/facebook.svg';
import twitIcon from '../assets/images/icons/twitter.svg';
import blogIcon from '../assets/images/icons/blog.svg';
import ytubeIcon from '../assets/images/icons/youtube.svg';
import instaIcon from '../assets/images/icons/instagram.svg';
import playBtn from '../assets/images/icons/play-btn.png';

import banner1 from '../assets/images/center-banner3.jpg';
import banner2 from '../assets/images/center-banner4.jpg';

import feature1 from '../assets/images/icons/benefits/innovation.png';
import feature2 from '../assets/images/icons/benefits/capsule.png';
import feature3 from '../assets/images/icons/benefits/rocket.png';
import feature4 from '../assets/images/icons/benefits/online-learning.png';
import feature5 from '../assets/images/icons/benefits/question.png';
import feature6 from '../assets/images/icons/benefits/solution.png';
import feature7 from '../assets/images/icons/benefits/famous.png';

import engIcon from '../assets/images/icons/engineering.png';
import resultIcon from '../assets/images/icons/trophy.png';
import medicalIcon from '../assets/images/icons/doctor.png';
import foundationIcon from '../assets/images/icons/left-up.png';
import pdfIcon from '../assets/images/icons/benefits/pdf.png';

import videoCover1 from '../assets/images/test-cover/cover1.jpg';
import videoCover2 from '../assets/images/test-cover/cover2.jpg';
import videoCover3 from '../assets/images/test-cover/cover3.jpg';
import videoCover4 from '../assets/images/test-cover/cover4.jpg';
import videoCover5 from '../assets/images/test-cover/cover5.jpg';
import videoCover6 from '../assets/images/test-cover/cover6.jpg';
import videoCover7 from '../assets/images/test-cover/cover7.jpg';
import videoCover8 from '../assets/images/test-cover/cover8.jpg';

// import infoBrochure from '../assets/images/documenzts/info-brochure-new.pdf';

const IcadCpa = props => {
    const [scroll, setScroll] = useState(false);    
      const [count, setCount] = useState(0);
      const counters = document.querySelectorAll('.counter');
      const speed = 500; // The lower the slower
    
    counters.forEach(counter => {
        const updateCount = () => {
            const target = +counter.getAttribute('data-target');
            const count = +counter.innerText;
    
            // Lower inc to slow and higher to slow
            const inc = target / speed;
    
            // console.log(inc);
            // console.log(count);
    
            // Check if target is reached
            if (count < target) {
                // Add inc to count and output in counter
                counter.innerText = Math.round(count + inc);
                // Call function every ms
                setTimeout(updateCount, 1);
            } else {
                counter.innerText = target;
            }
        };
    
        updateCount(setCount);
    });

    useEffect(() => {
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 50);
        });
      }, []);
    
    useEffect(() => {
      setCount();
    }, [count]);

    ////////////

    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [modalSize, setModalSize] = useState('lg');

    const testimonial_one = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/dBpchQstNiE" title="| ICAD | 🎯 Success through Focus and Passion!" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_two = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/Q0oBwvEVYWw" title="| ICAD | 🌟 Breaking Boundaries with Brilliance!" frameborder="0" allow='autoplay' allowfullscreen></iframe>
        );
    };
    const testimonial_three = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/fu78vzOrl_A" title="| ICAD | 🌟 Rising to the Challenge!" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_four = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/49zj4N8TGUA" title="| ICAD |  Turning Challenges into Triumph!" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_five = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/lgxotDgqiaw" title="| ICAD | 🎉 Victory through Dedication!" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_six = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/i6-Gqj2xImA" title="Success speaks louder than words" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_seven = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/8Mg-MURLv_0" title="Happy Student of ICAD. #icad #education" frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };
    const testimonial_eight = () => {
        return(
            <iframe width="356" height="634" src="https://www.youtube.com/embed/D11H9xss-64" title="Rohit Dhore has truly exemplified dedication and excellence during his time at ICAD." frameborder="0" allow='autoplay' allowfullscreen style={{display:'block',margin:'0 auto'}}></iframe>
        );
    };


    const openModal = (title, body, size) => {
        setModalTitle(title);
        setModalBody(body);
        setModalSize(size);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
        setModalTitle('');
        setModalBody('');
      };

      ///slider
      var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        className: "almuni_slider",
        autoplay: true,
        pauseOnHover: true,
        adaptiveHeight: true,
        responsive: [      
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 561,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
    
    
    return (
        <>
            <Helmet>
                <title>ICAD CPA</title>
                <meta name="description" content="Your CPA Dream, Our Support." />
                <meta name="keywords" content="ICAD CPA, ICAD Scholarship" />
            </Helmet>

            <div className={scroll ? "sticky top_nav cpa_top" : "top_nav cpa_top"}>
                <Container fluid="lg">
                    <div className='d-flex'>
                        <div className='top_left'>
                            <span><img src={logo} alt="logo" /></span>                        
                        </div>
                        <div className='top_center'>
                            <ul>
                                <li><b>Call Us:</b> +91-9355870236, +91-9355870232</li>
                                <li><b>Mail Us:</b> contact@icadiit.com</li>
                            </ul>
                        </div>
                        <div className='top_right social_icon'>
                            <ul>
                                <li><a href='https://www.facebook.com/jee.icad/' target="_blank"><img src={facebookIcon} className='svg_img'/></a></li>
                                <li><a href='https://twitter.com/#!/ICADSL' target="_blank"><img src={twitIcon} className='svg_img'/></a></li>
                                <li><a href='https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch' target="_blank"><img src={ytubeIcon} className='svg_img'/></a></li>
                                <li><a href='http://icadsl.blogspot.com/' target="_blank"><img src={blogIcon} className='svg_img'/></a></li>
                                <li><a href='https://www.instagram.com/icadsl/?utm_source=ig_profile_share&amp;igshid=1m6rhkv66v0yd' target="_blank"><img src={instaIcon} className='svg_img'/></a></li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </div>

            <section className='banner_slider cpa_banner'>
                <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
                    {/* <Carousel.Item>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" target='_blank'>
                            <img
                            className="d-block w-100"
                            src={banner1}
                            alt="first slide"
                            />
                        </a>
                    </Carousel.Item> */}
                    <Carousel.Item>
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" target='_blank'>
                            <img
                            className="d-block w-100"
                            src={banner2}
                            alt="second slide"
                            />
                        </a>
                    </Carousel.Item>
                </Carousel>
            </section>     
            
            {/* <section className='bg_light_gray'>
                <Container>
                    <Row>
                        <Col sm={12} className='text-center'>
                            <div className='mb-5' data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <h1 className='title cpa_title mb-3'>"Your CPA Dream, Our Support<br/>Win Up to 90% Scholarship Today!"</h1>
                                <h2 className='cpa_subtitle'>Kickstart your CPA journey with unmatched guidance, expert resources, <br/>and scholarships that make success affordable.</h2>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <Image src={cpaMain} alt='CPA image' className='cpa_main_img' fluid></Image>
                            </div>
                        </Col>                        
                    </Row>
                </Container>
            </section> */}
            <section className='mt-50'>
                <Container>
                    <Row>
                        <Col sm={12} className='text-center'>
                            <div className='mb-5' data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <h1 className='title mb-3'>Key Features of ICAD</h1>
                                <h2 className='cpa_subtitle'>Here are some of our multitude of Key Features,
                                empowering you to achieve your goals.</h2>
                            </div>                            
                        </Col>                        
                    </Row>
                    <Row className='justify-content-center' data-aos="fade-up" data-aos-duration="3000">
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature1} alt="Specialized learning techniquenics icon"></Image>
                                </div>
                                <h6>Specialized learning techniques</h6>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature2} alt="Command capsule icon"></Image>
                                </div>
                                <h6>Command capsule</h6>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature3} alt="Rank booster icon"></Image>
                                </div>
                                <h6>Rank booster</h6>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature4} alt="Online app icon"></Image>
                                </div>
                                <h6>Online app</h6>
                            </div>
                        </Col>
                    
                        <Col lg={3} md={6} sm={6} className='feature_sec'>                              
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature5} alt="Doubt clearing icon"></Image>
                                </div>
                                <h6>Doubt clearing</h6>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature6} alt="Solving system icon"></Image>
                                </div>
                                <h6>Solving system</h6>
                            </div>
                        </Col>
                        <Col lg={3} md={6} sm={6} className='feature_sec'>
                            <div className='feature_div'>
                                <div className='icon'>
                                    <Image src={feature7} alt="Personalized attention icon"></Image>
                                </div>
                                <h6>Personalized attention</h6>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className='benifits_row d-flex'>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="3000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits1} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Command capsule</h4>
                                    </div>
                                </div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits2} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Personalized attention</h4>
                                    </div>
                                </div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="1000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits4} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Ai-assisted rank booster app</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div data-aos="zoom-in-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <Image src={cpaFeature} alt='CPA image' className='cpa_main_img mx-auto d-block' style={{padding:'15px',margin:'15px 0px'}} fluid></Image>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div>
                                <div className='service' data-aos="fade-up" data-aos-duration="3000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits3} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>   
                                    <div className='content'>
                                        <h4 className='mb-1'>Rank booster</h4>
                                    </div>
                                </div>
                                <div className='service' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits5} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Doubt clearance</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </section>
            <section className='mt-50 bg_light_gray large_bottom' data-aos="fade-up" data-aos-duration="3000">
                <Container>               
                    <Row>
                        <Col sm={12}>              
                            <div className='mb-5'>
                                <h1 className='title text-center mb-3'>Why Choose ICAD?</h1>
                                <p>At ICAD, we believe every student deserves personalized guidance. Here’s why we’re the 
                                right choice for your academic journey: </p>
                                <ul className='check_list'>
                                <li>Innovative Learning Resources: Our Command Capsules and Digital Classes offer concise 
                                theory, real-life applications, and exam-oriented numericals.</li>
                                <li>Personalized Attention: Every student gets one-on-one mentorship, regular tests, and 
                                assessments to identify strengths and weaknesses. </li>
                                <li>Proven Track Record: With over two decades of experience and thousands of successful 
                                students, ICAD stands as a trusted institution.</li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row className=''>
                    <Col sm={12}>
                        <Row>
                        <Col lg={6} md={12} sm={12} className='mt-3'>
                            <Card className='theme_card course_card'>
                            <Card.Body>
                                <div className='icon_div mb-15'><Image src={engIcon} fluid></Image></div>
                                <h4>Engineering</h4>
                                <p>ICAD helps students prepare for JEE exams to get into the best engineering colleges like IITs and NITs. With expert teachers, easy-to-understand materials, and regular practice, we guide them to achieve their goals.</p>
                                <a href='/engineering'>View Details-></a>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} sm={12} className='mt-3'>
                            <Card className='theme_card course_card'>
                            <Card.Body>
                                <div className='icon_div mb-15'><Image src={medicalIcon} fluid></Image></div>
                                <h4>Medical</h4>
                                <p>ICAD NEET coaching helps students get into top medical colleges like AIIMS and GMC. With experienced teachers, simple study materials, and regular tests, we make sure they are ready to succeed.</p>
                                <a href='/medical'>View Details-></a>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} sm={12} className='mt-3'>
                            <Card className='theme_card course_card h-100'>
                            <Card.Body>
                                <div className='icon_div mb-15'><Image src={foundationIcon} fluid></Image></div>
                                <h4>Foundation</h4>
                                <p>A course for students transitioning to higher education, focusing on study skills, critical thinking, and subject-specific knowledge.</p>
                                <a href='/foundation'>View Details-></a>
                            </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} md={12} sm={12} className='mt-3'>
                            <Card className='theme_card course_card'>
                            <Card.Body>
                                <div className='icon_div mb-15'><Image src={resultIcon} fluid></Image></div>
                                <h4>Results</h4>
                                <p>ICAD is the most sought after institute in Maharashtra, when it comes to results of JEE/NEET & Foundation programmes. ICAD has produced some of countries finest ranks in JEE/NEET and the students of ICAD have even brought laurrels to the country by winning Medals in International Olympiads.</p>
                                <a href='/results'>View Details-></a>
                            </Card.Body>
                            </Card>
                        </Col>
                        </Row>
                    </Col>
                </Row>        
            </Container>
            </section>
            <section className='counters counter_sec mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <div className='main_div overlap_sec'>
                        <Row>
                            <Col xl={3} lg={6} md={6} sm={6}>
                                <div className='counter_item'>
                                    <h1 className='counter counter_no first' data-target="774">0</h1>
                                    <p className='counter_text'>Selections in JEE-Advanced</p>
                                </div>
                              
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6}>
                                <div className='counter_item'>
                                    <h1 className='counter counter_no' data-target="5467">0</h1>
                                    <p className='counter_text'>Selections in JEE-Main</p>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6}>
                                <div className='counter_item'>
                                    <h1 className='counter counter_no' data-target="6">6</h1>
                                    <p className='counter_text'>International Medals in Olympiad</p>
                                </div>
                            </Col>
                            <Col xl={3} lg={6} md={6} sm={6}>
                                <div className='counter_item'>
                                    <h1 className='counter counter_no' data-target="568">0</h1>
                                    <p className='counter_text'>Selections in NEET-UG</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            {/* <section className='mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={7} md={10} sm={12}>
                            <div className='pdf_div'>
                                <a href={infoBrochure} target='_blank'>
                                    Click here to Download Brochure 2025-2026
                                    <div><Image src={pdfIcon} alt="pdf-icon"></Image></div>
                                </a> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section className='bg_light_gray mt-50' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className='title mb-3 text-center'>What Our Students Say..</h1>
                    <Slider {...settings}>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover1} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_one, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Madhur Chandak</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover2} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_two, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Omisha Jain</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover3} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_three, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Pritosh Tupkari</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover4} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_four, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Varun Umredkar</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover5} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_five, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Agam Jain</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover6} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_six, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Annanya Behati</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover7} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_seven, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Amogh Berge</h5>
                            </div>
                        </div>
                        <div>
                            <div className="video_gallery testimonial" data-aos-delay="150" data-aos="fade-left" data-aos-duration="800">
                                <div className='thumbnail'>
                                    <Image src={videoCover8} alt="video thumb" fluid></Image>
                                    <a onClick={() => openModal('', testimonial_eight, 'md')} className='video_play_btn'>
                                        <Image src={playBtn} alt="play button" fluid></Image>
                                    </a>
                                </div>
                            </div>
                            <div className='cta_btn'>
                                <h5 className='text-center mb-0'>Rohit Dhore</h5>
                            </div>
                        </div>                        
                    </Slider>                   
                </Container>
            </section>
            <section  className="mt-50 custom_sec" data-aos="fade-up" data-aos-duration="3000" style={{marginBottom:'0px'}}>
                <Container className='text-center'>
                    <h2 className='subtitle mb-3'>Want to Boost Your Career -  Apply Now!</h2>
                    <h4 className='text-center mb-4 center_text'>"We understand your challenges. Let us help you overcome them with tailored support and financial aid that brings your CPA dream within reach."</h4>
                    <Button className="theme_btn" href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" target='_blank'>Claim Your Spot</Button>
                </Container>
            </section>
            <section className="mt-50 bg_light_gray" data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <h1 className='title mb-3 text-center'>Frequently Asked Questions and Answers</h1>
                    <Row>
                        <Col sm={12}>
                            <Card className='theme_card'>
                                <Card.Body>
                                    <Accordion className='theme_accordion' defaultActiveKey="0" flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>What is the CPA scholarship exam, and who are eligible to take it?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>CPA is a scholarship cum entrance exam conducted by ICAD for students moving in grades <b>7th to 10th</b>  , offering financial rewards and opportunities to join ICAD's programs for the preparation of various competitive exams.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>What are the benefits of appearing for the CPA exam?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Students can earn up to 90% scholarships, access expert guidance, and secure a head start in preparing for JEE, NEET, Olympiads, and other competitive exams.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>How can I register for the CPA exam?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Registration can be done online at <a href="https://icad.org.in/">www.icad.org.in</a>, by physically walking into any of the ICAD centres or you can call on 98230 37090.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>What is the exam pattern and syllabus for CPA?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>The exam consists of 40 MCQ Questions covering Maths, Science & Mental Ability. The syllabus is based on NCERT of the current class the student is studying in.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Can I take the CPA exam more than once?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Yes, a student is allowed a maximum of 2 attempts, and the better of the two performances will be considered for the final evaluation.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>Does ICAD provide sample papers or guidance for CPA preparation?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>Yes, sample papers and preparation tips are available at <a href="https://icadiit.com/downloads">https://icadiit.com/downloads</a>, or you can scan the qr code..</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>Whom should I contact for additional queries about the CPA exam?</Accordion.Header>
                                            <Accordion.Body>
                                                <p>For assistance, contact us at 98230 37090, email us at <a href="mailto:contact@icadiit.com">contact@icadiit.com</a> , or visit <a href="https://www.icad.org.in/">www.icad.org.in</a>.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
                       
            <Modal isOpen={isOpen} onClose={closeModal} title={modalTitle} body={modalBody} size={modalSize} />
        </>
    );
};

export default IcadCpa; 